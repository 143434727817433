<template>
  <app-form-view
    multipart
    app="contact"
    model="personaldocument"
    api-url="contact/personal-doc/"
    :title="$t('menu.stakeholderDocument')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveFields"
    :prepare-data="prepareData"
    v-model="formData"
    @input="loadDocuments"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" md="6">
          <v-row
            ><v-col cols="12" lg="12">
              <app-input
                name="name"
                :label="$t('fields.documentName')"
                :view="view"
                v-model="formData.document_name"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="contact"
                type="chips-server"
                rules="required"
                :label="$t('fields.contact')"
                :view="view"
                :binds="{
                  apiUrl: 'contact/contact/?contact_type=company',
                  chip: {
                    color: 'secondary',
                    icon: 'mdi-account-circle-outline',
                    value: 'name',
                    router: contactRouter
                  }
                }"
                :filterObject="filterObject"
                v-model="formData.contact_ids"
              />

              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_contact_ids"
              />
            </v-col>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="type"
                rules="required"
                :label="$t('fields.type')"
                :view="view"
                value="company"
                disabled
              >
                <template v-slot:view-value="{ value }">
                  {{ $t(`label.${value}`) }}
                </template>
              </app-input>

              <app-input
                v-if="isSentToApprove"
                name="approveType"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_type"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="documentTemplate"
                rules="required"
                :label="$t('fields.documentTemplate')"
                :view="view"
                value="บอจ.5"
                disabled
              />

              <app-input
                v-if="isSentToApprove"
                name="approveDocumentTemplate"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_template_id"
              />
            </v-col>
            <template v-if="formData.document_template_id">
              <v-col
                v-for="field in documentTemplateFields"
                cols="12"
                md="6"
                class="app-input--approve-wrapper"
                :key="field.field"
              >
                <app-input
                  :rules="{
                    required: true,
                    numeric: field.field_type === 'number'
                  }"
                  :name="field.field"
                  :type="field.field_type"
                  :label="field.name"
                  :items="fieldSelectionItems(field)"
                  :view="view"
                  v-model="formData.fields[field.field]"
                />

                <app-input
                  v-if="isSentToApprove"
                  type="switch"
                  class="approve"
                  :name="`approve-${field.name}`"
                  :label="$t('fields.approve')"
                  v-model="formData[`approve_${field.field}`]"
                />
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="customer_name"
                type="select-server"
                rules="required"
                :label="$t('fields.customerCompany')"
                :view="view"
                :binds="{
                  apiUrl: 'customer-company/customer_company/?active=true'
                }"
                v-model="formData.customer_company_id"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveCustomerCompany"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_customer_company"
              />
            </v-col>
          </v-row>
          <v-row v-if="!view">
            <v-col cols="12" lg="12">
              <app-input
                name="documents"
                type="file"
                :label="$t('fields.document')"
                :view="view"
                :binds="{ multiple: true, chips: true }"
                v-model="formData.documents"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="12">
              <app-input
                name="note"
                type="textarea"
                :label="$t('fields.note')"
                :view="view"
                v-model="formData.note"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="12">
              <app-input
                name="active"
                type="switch"
                :label="$t('fields.active')"
                :view="view"
                v-model="formData.active"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <app-preview-file
            :file-ready="documentReady || mode === 'create'"
            :files="formData.documents || []"
          />
        </v-col>
      </v-row>

      <v-card>
        <v-tabs background-color="primary" dark>
          <v-tab> Stakeholder Document </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="special_document"
                    model="stakeholderdocumentheader"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :serverItems="items"
                    :clientItems="items"
                    :headers="headers"
                    :loading="loading"
                    :server-items-length="serverItemsLength"
                    v-model="selected"
                    v-bind="binds"
                    @delete="onDeleteItem"
                    @server="getItems"
                    @client="getClientItems"
                    @edit="onEdit"
                    itemKey="id"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>{{
                              $t('label.stakeholder')
                            }}</v-toolbar-title>

                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="1000px">
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="selected && mode === 'edit'"
                                    color="error"
                                    @click="onDelete(selected)"
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.seq"
                                          name="seq"
                                          :label="$t('fields.seq')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.contact_id"
                                          name="contact_id"
                                          type="select-server"
                                          :label="$t('fields.contact_id')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: 'contact/contact/'
                                          }"
                                          :filterObject="filterObject"
                                          @input="getDataDetails"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.prefix_id"
                                          name="prefix_id"
                                          type="select-server"
                                          :label="$t('fields.prefix')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: 'contact/contact-prefix/'
                                          }"
                                          ref="prefix"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.id_card"
                                          name="id_card"
                                          :label="$t('fields.idNo')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.first_name"
                                          name="first_name"
                                          :label="$t('fields.firstName')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>

                                      <v-col cols="12" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.last_name"
                                          name="last_name"
                                          :label="$t('fields.lastName')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.stock_type"
                                          name="stock_type"
                                          :label="$t('fields.stock_type')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.total_stock"
                                          name="total_stock"
                                          :label="$t('fields.total_stock')"
                                          :view="view"
                                          :rules="{
                                            numeric: true
                                          }"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.stock_price"
                                          name="stock_price"
                                          :label="$t('fields.stock_price')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.stock_percentage"
                                          name="stock_percentage"
                                          :label="$t('fields.stock_percentage')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.stock_number_from"
                                          name="stock_number_from"
                                          :label="
                                            $t('fields.stock_number_from')
                                          "
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.stock_number_to"
                                          name="stock_number_to"
                                          :label="$t('fields.stock_number_to')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="editedItem.stock_number_date"
                                          name="stock_number_date"
                                          :label="
                                            $t('fields.stock_number_date')
                                          "
                                          :view="view"
                                          type="datepicker"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="
                                            editedItem.stock_owner_date_from
                                          "
                                          name="stock_owner_date_from"
                                          :label="
                                            $t('fields.stock_owner_date_from')
                                          "
                                          :view="view"
                                          type="datepicker"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="4" sm="6" md="6">
                                        <app-input
                                          v-model="
                                            editedItem.stock_owner_date_to
                                          "
                                          name="stock_owner_date_to"
                                          :label="
                                            $t('fields.stock_owner_date_to')
                                          "
                                          :view="view"
                                          type="datepicker"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>

      <slot :view="view" />
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppPreviewFile from '@components/AppPreviewFile'
import AppTable from '@components/AppTable.vue'
import { getFile } from '@utils/function'

export default {
  name: 'stakeholder-document-form',
  components: {
    AppInput,
    AppFormView,
    AppPreviewFile,
    AppTable
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    headers() {
      return [
        { text: this.$t('fields.seq'), value: 'seq' },
        { text: this.$t('fields.prefix'), value: 'prefix_id.name' },
        { text: this.$t('fields.firstName'), value: 'first_name' },
        { text: this.$t('fields.lastName'), value: 'last_name' },
        { text: this.$t('fields.idNo'), value: 'id_card' },
        { text: this.$t('fields.stock_type'), value: 'stock_type' },
        { text: this.$t('fields.total_stock'), value: 'total_stock' },
        { text: this.$t('fields.stock_price'), value: 'stock_price' },
        {
          text: this.$t('fields.stock_number_from'),
          value: 'stock_number_from'
        },
        { text: this.$t('fields.stock_number_to'), value: 'stock_number_to' },
        {
          text: this.$t('fields.stock_number_date'),
          value: 'stock_number_date'
        },
        {
          text: this.$t('fields.stock_owner_date_from'),
          value: 'stock_owner_date_from'
        },
        {
          text: this.$t('fields.stock_owner_date_to'),
          value: 'stock_owner_date_to'
        },
        { text: this.$t('fields.stock_percentage'), value: 'stock_percentage' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    documentTemplateFields() {
      const fields = [...this.formData.document_template_id.fields]
      fields.sort((a, b) => {
        if (a.code < b.code) return -1
        if (a.code > b.code) return 1
        return 0
      })
      return fields
    },
    approveFields() {
      return this.formData.document_template_id
        ? [...this.formData.document_template_id.fields]
            .map((field) => 'approve_' + field.field)
            .concat([
              'approve_contact_ids',
              'approve_type',
              'approve_document_template_id'
            ])
        : []
    }
  },
  props: {
    binds: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      documentReady: false,
      formData: {
        state: 'draft',
        fields: {}
      },
      search: '',
      items: [],
      selected: [],
      savedItem: {},
      serverItemsLength: 0,
      loading: false,
      editedItem: {
        prefix_id: null,
        seq: null,
        first_name: null,
        last_name: null,
        id_card: null,
        stock_type: null,
        total_stock: null,
        stock_price: null,
        stock_percentage: null
      },
      dialog: false,
      dialogDelete: false,
      apiMethod: 'post',
      editId: null
    }
  },
  watch: {
    'formData.document_name': function (value) {
      if (this.$route.name === 'stakeholderDocumentEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'stakeholderDocumentEdit',
          text: value ?? 'N/A',
          to: {
            name: 'stakeholderDocumentEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    contactRouter(item) {
      return {
        name: 'contactEdit',
        params: { id: item.id }
      }
    },
    async loadDocuments() {
      this.formData.documents = await Promise.all(
        this.formData.documents.map((document) => getFile(document))
      )
      this.documentReady = true
    },
    fieldSelectionItems(field) {
      if (field.field_type !== 'select') return []
      return field.selection_members.map((value) => ({
        ...value,
        value: value.label
      }))
    },
    filterObject(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.national_id_no
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    prepareData(data) {
      data.contact_ids = data.contact_ids.map((value) => value.id)
      data.document_template_id = data.document_template_id.id
      data.customer_company_id = data.customer_company_id.id
      data.type = 'company'
      return data
    },
    onEdit(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialog = true
    },
    save() {
      this.loading = true
      if (
        this.editedItem.contact_id !== null &&
        this.editedItem.contact_id !== undefined
      ) {
        this.savedItem.contact_id = this.editedItem.contact_id.id
      }
      this.savedItem.prefix_id = this.editedItem.prefix_id.id
      this.savedItem.seq = this.editedItem.seq
      this.savedItem.first_name = this.editedItem.first_name
      this.savedItem.last_name = this.editedItem.last_name
      this.savedItem.id_card = this.editedItem.id_card
      this.savedItem.personal_id = this.$route.params.id
      this.savedItem.stock_type = this.editedItem.stock_type
      this.savedItem.total_stock = this.editedItem.total_stock
      this.savedItem.stock_price = this.editedItem.stock_price
      this.savedItem.stock_percentage = this.editedItem.stock_percentage
      this.savedItem.stock_number_from = this.editedItem.stock_number_from
      this.savedItem.stock_number_to = this.editedItem.stock_number_to
      this.savedItem.stock_number_date = this.editedItem.stock_number_date
      this.savedItem.stock_owner_date_from =
        this.editedItem.stock_owner_date_from
      if (
        this.editedItem.stock_owner_date_to !== null &&
        this.editedItem.stock_owner_date_to !== undefined
      ) {
        this.savedItem.stock_owner_date_to = this.editedItem.stock_owner_date_to
      }

      const data = this.savedItem
      var itemID = ''
      if (this.editId !== null) {
        itemID = this.editId + '/'
      }

      this.$api({
        method: this.apiMethod,
        url: `special-doc/stakeheader-doc/` + itemID,
        data
      })
      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.close()
      this.getItems()
    },
    getItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `special-doc/stakeheader-doc?personal_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.items = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `special-doc/stakeheader-doc/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    getDataDetails() {
      if (
        this.editedItem.contact_id !== null &&
        this.editedItem.contact_id !== undefined
      ) {
        this.editedItem.prefix_id = this.editedItem.contact_id.prefix_id
        this.editedItem.first_name = this.editedItem.contact_id.first_name
        this.editedItem.last_name = this.editedItem.contact_id.last_name
        this.editedItem.id_card = this.editedItem.contact_id.national_id_no
        this.$refs.prefix.setDefaultServerItems(this.editedItem.prefix_id)
      }
    },
    getDocData() {
      this.$api({
        method: 'get',
        url: `doc-template/document/?search=บอจ.5`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.formData.document_template_id = data.results[0]
        this.loading = false
      })
    }
  },
  created() {
    this.getDocData()
    if (this.$route.name === 'stakeholderDocumentCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'stakeholderDocumentCreate'
      })
    }
  }
}
</script>
